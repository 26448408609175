import axios from '@axios'

export default {
  namespaced: true,
  state: {
    cartItemsCount: (() => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData && userData.extras ? userData.extras.eCommerceCartItemsCount : 0
    })(),
  },
  getters: {},
  mutations: {
    UPDATE_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count
    },
  },
  actions: {
    createPreference(ctx, payload){
      return new Promise((resolve, reject) => {
        axios
          .post('/mp/create-preference', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProducts(ctx, { type }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/ecommerce/products/list', { type })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/ecommerce/products/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWishlistProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/ecommerce/wishlist')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCartProducts() {
      return new Promise((resolve, reject) => {
        axios
          .post('/ecommerce/products/get-cart')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // ------------------------------------------------
    // Product Actions
    // ------------------------------------------------
    addProductInWishlist(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/ecommerce/wishlist', { productId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromWishlist(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/ecommerce/wishlist/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addProductInCart(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/ecommerce/products/add-cart', { product_uuid:productId, quantity:1 })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromCart(ctx, { productId, quantity }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/ecommerce/products/remove-cart`, { product_uuid:productId, quantity})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBusiness(ctx){
      return new Promise((resolve, reject) => {
        axios
          .post('/account/settings',{})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createInvoice(ctx, {customer}){
      return new Promise((resolve, reject) => {
        axios
          .post('ecommerce/invoice/create',{ customer })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
