import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('session') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

/** lsandoval: 25/01/2022 We obtain user data from session **/
export const getUserData = () => JSON.parse(localStorage.getItem('session'))

export const getSession = () => JSON.parse(localStorage.getItem('session'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
/** lsandoval: 14/01/2022 We update roles for the app */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'fg.root') return '/'
  if (userRole === 'fg.customer') return { name: 'access-control' }
  return { name: 'auth-login' }
}


/** lsandoval: 14/01/2022 We update roles for the app */
export const getRoleForLoggedInUser = userRole => {
  if (userRole === 'fg.root') return 'Central Admin'
  if (userRole === 'fg.accountant') return 'Accountant'
  if (userRole === 'fg.customer') return 'Customer'
  return 'Customer'
}
