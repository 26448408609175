import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVueIcons } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// lsandoval: 14/01/2022 Integrate Firebase Auth
import { initializeApp } from 'firebase/app'
// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
//import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
// import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVueIcons)

// Composition API
Vue.use(VueCompositionAPI)

// Uppercase diective
Vue.directive('uppercase', {
  bind(el, _, vnode) {
    el.addEventListener('input', (e) => {
      e.target.value = e.target.value.toUpperCase()
      vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
    })
  },
})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = true

// factura-green-dev Firebase config

/** DEPRECIATED */
/**const firebaseConfig = {
  apiKey: "AIzaSyA7r7V8sBK2xK-LNqZoAXggfiYZ96Ml1j4",
  authDomain: "factura-green-dev.firebaseapp.com",
  projectId: "factura-green-dev",
  storageBucket: "factura-green-dev.appspot.com",
  messagingSenderId: "105860755145",
  appId: "1:105860755145:web:acfd597de2b6820d8ba718"
}**/

const firebaseConfig = {
  apiKey: "AIzaSyAEUVrlI19xfzqR5gz5AUvOYEt1CIA2apQ",
  authDomain: "facturagreen-alpha.firebaseapp.com",
  projectId: "facturagreen-alpha",
  storageBucket: "facturagreen-alpha.appspot.com",
  messagingSenderId: "170464518486",
  appId: "1:170464518486:web:b6c5abd68af069bc2bf6f7"
};

/*  const firebaseConfig = {
  apiKey: "AIzaSyBFrRjqPpohm95_70LcUw5jbAwbwf3Wq0Y",
  authDomain: "facturagreen-live.firebaseapp.com",
  projectId: "facturagreen-live",
  storageBucket: "facturagreen-live.appspot.com",
  messagingSenderId: "808789902863",
  appId: "1:808789902863:web:9ab4d4d564fa8b7e1f457a",
  measurementId: "G-0PK9QTMFXF"
}*/

// Initialize Firebase
initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
