import { DateTime } from "luxon";

export default {
  namespaced: true,
  state: {
    weeksLeft: 0,
    daysLeft: 0,
    off: false,
    isCsdLoaded: false,
    date: DateTime.now()
  },
  getters: {
    currentState: state => {
      const { weeksLeft, daysLeft, off, date, isCsdLoaded } = state
      return { weeksLeft, daysLeft, off, date, isCsdLoaded }
    },
  },
  mutations: {
    expiry(state, val) {
        if(val){
          const date = DateTime.fromISO(val)
          const {weeks, days} = date.diffNow(['weeks','days'])
          state.date = date.toFormat('dd/LL/yyyy');
          state.isCsdLoaded = true;
          state.weeksLeft = weeks;
          state.daysLeft = days;
        }
        else{
          state.isCsdLoaded = false;
        }
    },
    off(state, val) {
      state.off = val
    },
  },
  actions: {},
}
