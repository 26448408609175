import { Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
// lsandoval: 25/01/2022 Updates the session property to get ability that we can navigate into
const session = JSON.parse(localStorage.getItem('session'))
const existingAbility = session ? session.account.ability : null
export default new Ability(existingAbility || initialAbility)
